import React from 'react'
import Layout from '../layouts/layout.js'
import styled from 'styled-components'
import Pagetitle from '../components/Pagetitle'

const Index = () => (
  <Layout>
    <div>
      <Pagetitle title="Welcome to Wayward Media" />
      <p>Thankyou for visiting my site, parts of the site are currently in development and predominently use the site to test out the latest in web technology.</p>
    </div>
  </Layout>
)

export default Index
